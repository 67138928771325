<template>
  <div class="main" data-title="销售统计" v-title>
    <div class="table-wrapper">
      <table class="table">
        <tr>
          <th rowspan="2">部门</th>
          <th rowspan="2">销售顾问</th>
          <th rowspan="2">留存意向客户数</th>

          <th rowspan="2">继续跟踪客户数</th>
          <th rowspan="2">当月跟踪客户数</th>
          <th rowspan="2">当月跟踪次数</th>

          <th rowspan="2">目标订单</th>

          <th rowspan="2">当月订单数</th>

          <th rowspan="2">客户接待数</th>
          <th rowspan="2">留档客户数</th>
          <th colspan="2">来店客户数</th>
          <th rowspan="2">转介绍</th>
          <th colspan="2">其它业务</th>
          <th rowspan="2">当月新增客户</th>
          <th colspan="2">试乘试驾客户数</th>
          <th rowspan="2">新增客户转化订单数</th>
          <th rowspan="2">留存客户转化订单数</th>
          <th rowspan="2">交车数</th>

          <th rowspan="2">上月遗留订单数</th>
          <th rowspan="2">未交订单数</th>

          <th rowspan="2">留档率</th>
          <th rowspan="2">邀约回店率</th>
          <th rowspan="2">试乘试驾率</th>
          <th rowspan="2">本月新增客户订单转化率</th>
          <th rowspan="2">上月留存客户订单转化率</th>
          <th rowspan="2">综合订单转化率</th>
          <th rowspan="2">订单达成率</th>
          <th rowspan="2">成交率</th>
          <th rowspan="2">目标达成率</th>
        </tr>
        <tr>
          <th>首次来店客户数</th>
          <th>约回客户数</th>
          <th>售后业务</th>
          <th>其他业务</th>
          <th>首次来店客户试驾数</th>
          <th>再次来店客户试驾数</th>
        </tr>

        <tr v-for="(item, index) in listData" :key="index">
          <td>{{ item.dept_name }}</td>
          <td>{{ $store.state.userList[item.seller_uid][0] }}</td>
          <td>{{ item.liucunkehu }}</td>

          <td>{{ item.jixugenzong }}</td>
          <td>{{ item.genzongkehu }}</td>
          <td>{{ item.genzongcishu }}</td>

          <td>{{ item.mubiaodingdan }}</td>

          <td>{{ parseInt(item.xinzengzhuandingdan) + parseInt(item.liucunzhuandingdan) }}</td>

          <td>{{ item.jiedaikehu }}</td>
          <td>{{ item.liudangkehu }}</td>
          <td>{{ item.shoucilaidian }}</td>
          <td>{{ item.zaicilaidian }}</td>
          <td>{{ item.zhuanjieshao }}</td>
          <td>{{ item.shouhouyewu }}</td>
          <td>{{ item.qitayewu }}</td>
          <td>{{ item.xinzengkehu }}</td>
          <td>{{ item.shoucishijia }}</td>
          <td>{{ item.zaicishijia }}</td>
          <td>{{ item.xinzengzhuandingdan }}</td>
          <td>{{ item.liucunzhuandingdan }}</td>
          <td>{{ item.jiaoche }}</td>

          <td>{{ item.yiliudingdan }}</td>
          <td>{{ item.weijiaodingdan }}</td>


          <td>{{ item.tj01 }}</td>
          <td>{{ item.tj02 }}</td>
          <td>{{ item.tj03 }}</td>
          <td>{{ item.tj04 }}</td>
          <td>{{ item.tj05 }}</td>
          <td>{{ item.tj06 }}</td>
          <td>{{ item.tj07 }}</td>
          <td>{{ item.tj08 }}</td>
          <td>{{ item.tj09 }}</td>
        </tr>
      </table>
    </div>
    <chart-search></chart-search>
  </div>
</template>
<script>
import { monthFormat } from '@/unit/unit.js'
import ChartSearch from '@/components/chart/ChartSearch.vue'
export default {
  components: {
    ChartSearch
  },
  data () {
    return {
      key: [monthFormat(), monthFormat()],
      dept_ids: [],
      last_day: 15,
      listData: [],
    }
  },
  mounted () {
    this.getListData()
  },
  methods: {
    setDeps (arr) {
      this.dept_ids = arr
      this.getListData()
    },
    isCurrFirst (item) {
      //进店时间
      let tmp_t1 = item.income_date.split('-')
      let t1 = tmp_t1[0] + '-' + tmp_t1[1]

      if (t1 >= this.key[0] && t1 <= this.key[1]) {
        return true
      } else {
        return false
      }
    },
    isTrial (item) {
      if (item.income_type == 1 && item.has_trial == 1) {
        return '是'
      } else {
        return '否'
      }
    },
    isTrial2 (item) {
      if (item.income_type == 2 && item.has_trial == 1) {
        return '是'
      } else {
        return '否'
      }
    },
    getNewData () {
      this.getListData()
    },
    getListData () {
      this.$axios
        .post('/statistic/data3', {
          begin_month: this.key[0],
          end_month: this.key[1],
          last_day: this.last_day,
          dept_ids: this.dept_ids,
        })
        .then((res) => {
          if (res.data.code == 200) {
            // this.listData = res.data.data.list
            let listData = res.data.data.list
            res.data.data.list.map((item, index) => {
              listData[index].tj01 = this.toPercent(
                item.liudangkehu / item.jiedaikehu
              )
              listData[index].tj02 = this.toPercent(
                item.zaicilaidian / (item.liucunkehu + item.xinzengkehu)
              )
              listData[index].tj03 = this.toPercent(
                (item.shoucishijia + item.zaicishijia) /
                (item.liucunkehu + item.xinzengkehu)
              )
              listData[index].tj04 = this.toPercent(
                item.xinzengzhuandingdan / item.xinzengkehu
              )
              listData[index].tj05 = this.toPercent(
                item.liucunzhuandingdan / item.liucunkehu
              )
              listData[index].tj06 = this.toPercent(
                (item.xinzengzhuandingdan + item.liucunzhuandingdan) /
                (item.xinzengkehu + item.liucunkehu)
              )
              listData[index].tj07 = this.toPercent(
                item.xinzengzhuandingdan / item.liucunzhuandingdan
              )
              listData[index].tj08 = this.toPercent(
                item.jiaoche / (item.liucunkehu + item.liudangkehu)
              )
              listData[index].tj09 = this.toPercent(
                item.jiaoche / item.mubiaodingdan
              )
            })
            this.listData = listData
          } else {
            this.$message.error(res.data.msg)
          }
        })
    },
    toPercent (point) {
      var str = Number(point * 100).toFixed(2)
      str += '%'
      return str
    },
    getCfgData (val) {
      if (val == '') {
        return '-'
      }
      let i = 0

      if (typeof val == 'string') {
        i = parseInt(val)
      } else {
        i = val
      }

      return this.$store.state.cfgData[i][0]
    },
  },
}
</script>
<style lang="less" scoped>
.table-wrapper {
  width: 100vw;
  height: 100vh;
  overflow-x: auto;
  .table {
    border-collapse: collapse;
    th,
    td {
      background: #f5f7fa;
      color: rgba(0, 0, 0, 0.85);
      border: 1px solid #ebeef5;
      padding: 7px 10px;
      font-size: 12px;
      word-break: keep-all;
      white-space: nowrap;
      text-align: center;
      &:first-child {
        border-left: none;
      }
    }

    td {
      background: #fff;
      color: #606266;
    }
  }
}
.chart-content {
  padding: 10px 20px;
}
</style>